/* this file is transformed by vux-loader */
/* eslint-disable */
import "core-js/modules/es.array.push.js";
import axios from "axios";
import LCFQheader from "../../LCFQheader";
import MyclRadio from "../../../common/cllcRadio/MyclRadio";
import { RxUtil } from "@/assets/util.js";
import { format } from "@/assets/app.js";
export default {
  name: "lcfqBywx",
  components: {
    LCFQheader,
    MyclRadio
  },
  data() {
    return {
      oneft: "/index",
      headTit: "新建申请",
      tit: "车辆维修保养申请",
      //下拉
      showListpage: false,
      showGinfo: false,
      //userSelect
      single: true,
      //false,  单选或多选
      allowSelect: true,
      showUserDialog: false,
      //部门
      showGroupDialog: false,
      allowSelect: true,
      nodeGroupVm: {},
      userID: "",
      solId: "",
      boDefId: "",
      formKey: "",
      resultFa: [],
      wpInfoData: {},
      data: {
        sqr_name: "",
        sqbm_name: "",
        jph_name: "",
        jlxh: ""
      }
    };
  },
  created() {
    this.cgORxj();
    // this.initBySolInstId();
  },

  methods: {
    initBySolInstId() {
      // let solId = this.$route.params.solId;
      this.solId = this.$route.params.solId;
      var url = _baseUrl + "/mobile/bpm/startForm.do?solId=" + this.solId;
      this.$ajax.post(url).then(response => {
        this.boDefId = response.data.formModels[0].boDefId;
        this.formKey = response.data.formModels[0].formKey;
        let sqrId = response.data.formModels[0].jsonData.sqr;
        let sqrName = response.data.formModels[0].jsonData.sqr_name;
        let sqr_name = [{
          id: sqrId,
          text: sqrName
        }];
        this.data.sqr_name = JSON.stringify(sqr_name);
        let sqbmId = response.data.formModels[0].jsonData.sqbm;
        let sqbmName = response.data.formModels[0].jsonData.sqbm_name;
        let sqbm_name = [{
          id: sqbmId,
          text: sqbmName
        }];
        this.data.sqbm_name = JSON.stringify(sqbm_name);
      });
    },
    async cgORxj() {
      this.solId = this.$route.params.solId;
      let instId = this.$route.params.instId;
      if (instId != 0) {
        this.headTit = "我的草稿";
        this.oneft = "/myDrafts";
        var url = _baseUrl + "/mobile/bpm/startForm.do?solId=" + this.solId + "&instId=" + instId;
        this.$ajax.post(url).then(response => {
          this.data = response.data.formModels[0].jsonData;
          this.actDefId = response.data.bpmSolution.actDefId;
          this.boDefId = response.data.formModels[0].boDefId;
          this.formKey = response.data.formModels[0].formKey;
          let sqrId = response.data.formModels[0].jsonData.sqr;
          let sqrName = response.data.formModels[0].jsonData.sqr_name;
          let sqr_name = [{
            id: sqrId,
            text: sqrName
          }];
          this.data.sqr_name = JSON.stringify(sqr_name);
          let sqbmId = response.data.formModels[0].jsonData.sqbm;
          let sqbmName = response.data.formModels[0].jsonData.sqbm_name;
          let sqbm_name = [{
            id: sqbmId,
            text: sqbmName
          }];
          this.data.sqbm_name = JSON.stringify(sqbm_name);
        });
      } else {
        this.initBySolInstId();
      }
    },
    //选代理人
    selectUserDialog(vm) {
      this.data.sqr_name = '';
      this.showUserDialog = true;
      this.$refs.sysUser.search();
      // 设置选择用户。
      this.$refs.sysUser.setValue("");
      //设置选中的rx-box 控件，用于传递数据。
      this.nodeUserVm = vm;
    },
    selectUser(data) {
      var list = [];
      for (var i = 0; i < data.length; i++) {
        var obj = data[i];
        var o = {};
        o.id = obj.userId;
        o.text = obj.fullname;
        list.push(o);
      }
      var json = JSON.stringify(list);
      this.nodeUserVm.setValue(json);
      this.showUserDialog = false;
    },
    //科室选择
    selectGroupDialog(vm) {
      this.data.sqbm_name = '';
      this.showGroupDialog = true;
      this.$refs.sysGroup.type = "group";
      this.$refs.sysGroup.search();
      // 设置选择用户。
      this.$refs.sysGroup.setValue("");
      //设置选中的rx-box 控件，用于传递数据。
      this.nodeGroupVm = vm;
    },
    selectGroup(data) {
      var list = [];
      for (var i = 0; i < data.length; i++) {
        var obj = data[i];
        var o = {};
        var test = {};
        o.id = obj.groupId;
        o.text = obj.name;
        list.push(o);
      }
      var json = JSON.stringify(list);
      this.nodeGroupVm.setValue(json);
      this.showGroupDialog = false;
    },
    showList() {
      this.showListpage = true;
    },
    tdObj(item) {
      this.data.jph_name = item.CPH;
      this.data.jlxh = item.CLXH;
      this.showListpage = false;
    },
    datas() {
      let sqr = JSON.parse(this.data.sqr_name);
      let sqbm = JSON.parse(this.data.sqbm_name);
      var jsonDataObj = {
        bos: [{
          boDefId: this.boDefId,
          formKey: this.formKey,
          data: {
            sqr: sqr[0].id,
            sqr_name: sqr[0].text,
            sqbm: sqbm[0].id,
            sqbm_name: sqbm[0].text,
            jph: this.data.jph_name,
            jph_name: this.data.jph_name,
            jlxh: this.data.jlxh,
            gzms: this.data.gzms
          }
        }]
      };
      var jsonData = JSON.stringify(jsonDataObj);
      var params = new FormData();
      params.append("solId", this.solId);
      params.append("bpmInstId", "");
      params.append("jsonData", jsonData);
      return params;
    },
    //字段检查
    iN(str) {
      if (str == '' || str == null || typeof str == 'undefined') {
        return true;
      }
      return false;
    },
    //整体判断
    inspect() {
      if (this.iN(this.data.sqr_name) || this.iN(this.data.sqbm_name) || this.iN(this.data.jph_name) || this.iN(this.data.jlxh) || this.iN(this.data.gzms)) {
        return true;
      }
      return false;
    },
    onSubmit() {
      if (this.inspect()) {
        this.errorToast("启动失败！请检查信息是否填写完整", "1800");
        return;
      }

      // if(this.data.je != ""){
      var params = this.datas();
      var url = _baseUrl + "/bpm/core/bpmInst/startProcess.do";
      this.$dialog.loading.open('正在启动流程,请稍候...');
      this.$ajax.post(url, params).then(res => {
        this.$dialog.loading.close();
        var data = res.data;
        if (data.success) {
          this.$store.commit('cleanInstData');
          this.successToast("启动成功！", 1000, 'index', {
            from: 'startForm'
          });
        } else {
          this.errorToast("启动失败！" + data.data, "1800");
        }
      });
      // }else{
      //   this.errorToast("金额不能为空！");
      // }
    },

    onPreservation() {
      if (this.inspect()) {
        this.errorToast("保存失败！请检查信息是否填写完整", "1800");
        return;
      }
      var params = this.datas();
      var url = _baseUrl + "/bpm/core/bpmInst/saveDraft.do";
      this.$dialog.loading.open('正在保存草稿,请稍候...');
      this.$ajax.post(url, params).then(res => {
        this.$dialog.loading.close();
        var data = res.data;
        if (data.success) {
          this.$store.commit('cleanInstData');
          this.successToast("保存成功！", 1000, 'index', {
            from: 'startForm'
          });
        } else {
          this.errorToast("保存失败！" + data.data, "1800");
        }
      });
    },
    onLct() {
      this.$router.push({
        name: "procImage",
        params: {
          id: this.actDefId,
          type: 'start'
        }
      });
    }
  }
};